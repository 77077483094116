<script>
import { onUpdated } from "@vue/runtime-core";
import { useStore } from "vuex";
export default {
    props: {
        errMsg: String,
        className: String,
    },
    setup() {
        const store = useStore();
        onUpdated(() => {
            setTimeout(() => (store.state.errMsg = null), 5000);
        });
    },
};
</script>

<template>
    <div class="err" v-if="$store.state.errMsg">
        <p>{{ errMsg }}</p>
    </div>
</template>

<style lang="scss" scoped>
.error {
    background-color: orange;
}
.fatal {
    background-color: rgb(255, 17, 0);
}
.err {
    text-align: center;
    color: white;
    border-radius: 7px;
    padding: 10px 30px;
}
</style>
